<template>
  <html lang="en" class="notranslate" translate="no"><!-- All translators -->
  <head>
    <meta name="google" content="notranslate"/> <!-- Just for google -->
  </head>
  <div class="app">
    <Menu :list="menuList"/>

    <body>
    <div class="container mx-auto">
      <div class="row">
        <div class="flex-1 bg-gray-1 px-0 py-0 m-0">
          <Loading :isLoading="this.isLoading"/>
          <div class="flex bg-gray-000 h-34 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1;">
            <div class="center">
                <div class="col-xs-1"></div>
                <div class="col-xs-10">
                <br>
                <br>
                <br>
                <br>
                    <div>
                        <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                            <div class="row">
                            <center>
                                <p class="title">
                                    ¡Pago Exitoso!</p>
                            </center>
                            </div>
                        </div>
                        <br>
                        <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                            <div class="row">
                            <center>
                                <img src="@/assets/Cuenta/PagoParteSocial/pago-exitoso.svg" class="img-responsive" width="150" height="140"/>
                            </center>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                            <div class="row">
                            <center>
                                <p class="description">Su pago se ha realizado correctamente.</p>
                            </center>
                            </div>
                        </div>
                        <br>
                    </div>
                    <br>
                    <br>
                    <br>
                </div>
                <div class="col-xs-1"></div>
                
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </body>

  </div>

  </html>
</template>


<script>
import('@/assets/css/stylesheet.css');

import Menu from '../components/Menu.vue';
import Footer from '../components/Footer.vue';
import Loading from '@/components/Loading/VueLoading.vue';

import menuOptions  from "@/helpers/menu.js";
import { ref, onBeforeMount } from 'vue';

export default {
  name: 'PagoExitoso',
  setup() {
    let menuList = ref([]);

    onBeforeMount(async () => {
      await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
        menuList.value = resp.data.FONCABSA;
      }).catch(err => {
        console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList,
      errors: [],
      isLoading: false,
      apiToken: "",
      email: "",
      password: "",
      ccapi:process.env.VUE_APP_CCAPI
    };
  },
  components: {
    Menu,
    Footer,
    Loading
  },
  async mounted() {
    await this.activateAccount();
  }
}
</script>

<style scoped>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}


#Button_Access_LogIn {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #FFCC00;
  border: 1px solid #FFCC00;
  font-family: Roboto;
  font-weight: 400;
  width: 150px;
  height: 30px;
}

button {
  outline: none !important;
}
</style>